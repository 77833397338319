import React, { useEffect, useState } from "react";
import { Container, ListGroup, Button, Row, Col } from "react-bootstrap";
import ListItem from "../ListItems/ListItem";
import "./MyListPage.css";
import { useDispatch, useSelector } from "react-redux";
import { LIST_FOLLOWER } from "../../utilities/constants";
import LoadingBar, { movebar } from "../LoadingBar/LoadingBar";
import ListFilter, { filterLists } from "../MyListPage/ListFilter";
import {
  getUserListsProfilePageThunk,
  deleteListThunk,
} from "../../actions/list";
import CreateListModal from "../ListModals/CreateListModal";
import { FaHeart, FaTrashAlt } from "react-icons/fa";
import { getSearchOptions } from "../../utilities/sessionStorage";
import DeleteModal from "../ListModals/DeleteModal";
import { toast } from "react-toastify";
import { LoadingSkeleton } from "../LoadingSkeletons/MLPLoadingSkeleton";

export function EmptyPane({ msg }) {
  return (
    <div style={{ opacity: "50%" }} key={0} eventKey={"0"}>
      <div style={{ textAlign: "center" }}>{msg}</div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src="/images/no-list.png" />
      </div>
    </div>
  );
}

export default function MyListPage() {
  const lists = useSelector(state => state.user_lists);
  const dispatch = useDispatch();
  const [listFilter, setListFilter] = useState(false);
  const filteredLists = filterLists(listFilter, lists);

  const [deleteParams, updateDeleteParams] = useState({});
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      movebar("mlp-loadingbar");
      dispatch(getUserListsProfilePageThunk()).then(() => {
        setLoading(false);
      });
    } catch (err) {
      //Some error handling
    }
  }, []);

  function deleteList(params) {
    let searchOptions = getSearchOptions();

    console.log("searchOptions: ", searchOptions);
    console.log("params: ", params);

    if (searchOptions.label == params.list_name) {
      sessionStorage.removeItem("searchOptions");
    }
    // console.log("searchOptions (after): ", searchOptions)
    dispatch(deleteListThunk(params)).then(res =>
      res.status < 300
        ? toast.success(`Removed ${params.list_name}`)
        : toast.error(`Remove Failed`)
    );

    setDisplayConfirmationModal(false);
  }

  function ListNav() {
    const hideConfirmationModal = () => {
      setDisplayConfirmationModal(false);
    };

    return (
      <div className="static-list-container">
        <Row style={{ marginTop: "0.7rem" }}>
          <Col>
            <ListFilter
              defaultValue={listFilter}
              onChange={e => setListFilter(e)}
            />
          </Col>
          <Col>
            <CreateListModal title="CREATE A LIST">
              <Button className="create-list-button" variant="primary">
                Create a List
              </Button>
            </CreateListModal>
          </Col>
        </Row>
        <ListGroup>
          <div>
            {filteredLists.length > 0 ? (
              <div>
                {filteredLists.map((list, index) => (
                  <ListItem
                    href={`/list/${list.list_id}`}
                    key={index}
                    eventKey={index}
                    src={
                      list.thumbnail
                        ? `${process.env.REACT_APP_BASE_IMAGE_URL}${list.thumbnail}`
                        : "/images/default-tree.png"
                    }
                    heading={list.name}
                    subHeading={`${list.first_name} ${list.last_name}`}
                    updateTime={
                      list.date_updated ? list.date_updated : list.date_created
                    }
                    description={list.description}
                    tags={[
                      list.type,
                      list.isPublic ? "Public" : "Private",
                      list.regions ? list.regions.join(", ") : "",
                      list.keywords ? list.keywords.join(", ") : "",
                    ]}
                    actionItem={
                      <div style={{ alignSelf: "center" }}>
                        {list.role !== LIST_FOLLOWER ? (
                          <FaTrashAlt
                            className="Icon"
                            style={{ alignSelf: "center" }}
                            size={30}
                            onClick={e => {
                              e.stopPropagation();
                              updateDeleteParams({
                                index,
                                list_name: list.name,
                                list_id: list.list_id,
                                type_to_delete: "all",
                              });
                              setDeleteMessage(
                                `Are you sure you want to delete list: '${list.name}'?`
                              );
                              setDisplayConfirmationModal(true);
                            }}
                          />
                        ) : (
                          <FaHeart
                            className="Icon"
                            style={{ alignSelf: "center" }}
                            size={30}
                            onClick={e => {
                              e.stopPropagation();
                              updateDeleteParams({
                                index,
                                list_name: list.name,
                                list_id: list.list_id,
                                type_to_delete: "all",
                              });
                              setDeleteMessage(
                                `Are you sure you want to unfollow list: '${list.name}'?`
                              );
                              setDisplayConfirmationModal(true);
                            }}
                          />
                        )}
                      </div>
                    }
                  />
                ))}
              </div>
            ) : (
              <EmptyPane msg="There are no lists to display" />
            )}
          </div>
          <DeleteModal
            showModal={displayConfirmationModal}
            confirmModal={deleteList}
            hideModal={hideConfirmationModal}
            params={deleteParams}
            message={deleteMessage}
          />
        </ListGroup>
      </div>
    );
  }

  return (
    <Container>
      <h2 className="search-by-char-header text-center category-label p-3 m-0">
        MY LISTS
      </h2>
      <hr className="hr-bold-short-black mt-0 mb-3" />
      <LoadingBar id="mlp-loadingbar" idle_color="#212529" />
      {!loading ? <ListNav /> : <LoadingSkeleton />}
    </Container>
  );
}

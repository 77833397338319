export function convertPH(ph) {
  const table = {
    1: "Very Alkaline",
    2: "Alkaline",
    3: "Slightly Alkaline",
    4: "Neutral",
    5: "Slightly Acidic",
    6: "Acidic",
    7: "Very Acidic",
  };
  return table[ph];
}

export function convertSun(sun) {
  const table = {
    3: "Full Sun",
    2: "Partial Sun",
    1: "Full Shade",
  };
  return table[sun];
}

export function convertXArea(rating) {
  const table = {
    "$/TF": "-",
    "—": "—",
    1: "2.09 sq. in.",
    2: "2.24 sq. in.",
    3: "3.80 sq. in.",
    4: "4.75 sq. in.",
  };
  return table[rating];
}

import React from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";

import FormFieldCheckbox from "../FormField/FormFieldCheckbox";
import FormFieldSingleCheckbox from "../FormField/FormFieldSingleCheckbox";
import FormFieldMultiText from "../FormField/FormFieldMultiText";

import { optionsReturnForForm } from "../../utilities/searchChar";

class SiteConditions extends React.Component {
  constructor(props) {
    super();
    this.state = {
      plantingArea: [],
    };
  }

  optionsFor = field => {
    try {
      if (
        this.props.searchCharacteristics &&
        this.props.searchCharacteristics[field]
      ) {
        return optionsReturnForForm(this.props.searchCharacteristics[field]);
      } else {
        return [];
      }
    } catch (err) {
      console.log(`components.TreeFormCard.SiteConditions.optionsFor: ${err}`);
    }
  };

  render() {
    let parentComponent = this.props.parentComponent;
    return (
      <Container className="search-by-char-card--site-conditions">
        <Row>
          <Col lg={7}>
            <FormFieldMultiText
              title="Suitable Climate Regions"
              field="climate_region"
              options={[
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 },
                { label: 5, value: 5 },
                { label: 6, value: 6 },
                { label: 7, value: 7 },
                { label: 8, value: 8 },
                { label: 9, value: 9 },
                { label: 10, value: 10 },
              ]}
              parentComponent={parentComponent}
            />

            <FormFieldMultiText
              title="USDA Hardiness Zones"
              field="usda_zone"
              options={[
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 },
                { label: 5, value: 5 },
                { label: 6, value: 6 },
                { label: 7, value: 7 },
                { label: 8, value: 8 },
                { label: 9, value: 9 },
                { label: 10, value: 10 },
              ]}
              parentComponent={parentComponent}
            />

            <FormFieldMultiText
              title="Sunset Climate Zones"
              field="sunset_zone"
              options={[
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4", value: "4" },
                { label: "5", value: "5" },
                { label: "6", value: "6" },
                { label: "7", value: "7" },
                { label: "8", value: "8" },
                { label: "9", value: "9" },
                { label: "10", value: "10" },
                { label: "11", value: "11" },
                { label: "12", value: "12" },
                { label: "13", value: "13" },
                { label: "14", value: "14" },
                { label: "15", value: "15" },
                { label: "16", value: "16" },
                { label: "17", value: "17" },
                { label: "18", value: "18" },
                { label: "19", value: "19" },
                { label: "20", value: "20" },
                { label: "21", value: "21" },
                { label: "22", value: "22" },
                { label: "23", value: "23" },
                { label: "24", value: "24" },
                { label: "H1", value: "H1" },
                { label: "H2", value: "H2" },
              ]}
              parentComponent={parentComponent}
            />

            <FormFieldSingleCheckbox
              title="Recommended Planting Area"
              field="planting_area"
              options={this.optionsFor("plantingArea")}
              parentComponent={parentComponent}
            />

            <FormFieldSingleCheckbox
              title="SelecTree Water Use Rating"
              field="water_use"
              options={[
                { label: "Very Low", value: "Very Low" },
                { label: "Low", value: "Low" },
                { label: "Medium", value: "Medium" },
                { label: "High", value: "High" },
              ]}
              parentComponent={parentComponent}
            />

            <FormFieldCheckbox
              title="Soil Textures"
              field="soil_texture"
              options={[
                { label: "Clay", value: "Clay" },
                { label: "Loam", value: "Loam" },
                { label: "Sand", value: "Sand" },
              ]}
              parentComponent={parentComponent}
            />
            <FormFieldSingleCheckbox
              title="Salt Tolerance"
              field="salt_tolerance"
              options={[
                { label: "Low", value: "Low" },
                { label: "Moderate", value: "Moderate" },
                { label: "High", value: "High" },
              ]}
              parentComponent={parentComponent}
            />
            <FormFieldSingleCheckbox
              title="Shade Tolerant?"
              field="shade_tolerant"
              type="number"
              options={[
                { label: "No", value: 0 },
                { label: "Yes", value: 1 },
              ]}
              parentComponent={this.props.parentComponent}
            />
            <Row></Row>
          </Col>

          <Col lg={5}>
            <Row>
              <Col lg={6}>
                <FormFieldSingleCheckbox
                  title="Soil pH (Min)"
                  field="soil_ph_low"
                  options={[
                    { label: "Very Acidic", value: "7" },
                    { label: "Acidic", value: "6" },
                    { label: "Slightly Acidic", value: "5" },
                    { label: "Neutral", value: "4" },
                    { label: "Slightly Alkaline", value: "3" },
                    { label: "Alkaline", value: "2" },
                    { label: "Very Alkaline", value: "1" },
                  ]}
                  parentComponent={parentComponent}
                />
              </Col>

              <Col lg={6}>
                <FormFieldSingleCheckbox
                  title="Soil pH (Max)"
                  field="soil_ph_high"
                  options={[
                    { label: "Very Acidic", value: "7" },
                    { label: "Acidic", value: "6" },
                    { label: "Slightly Acidic", value: "5" },
                    { label: "Neutral", value: "4" },
                    { label: "Slightly Alkaline", value: "3" },
                    { label: "Alkaline", value: "2" },
                    { label: "Very Alkaline", value: "1" },
                  ]}
                  parentComponent={parentComponent}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { searchCharacteristics: state.searchCharacteristics.data };
};
export default connect(mapStateToProps, {})(SiteConditions);

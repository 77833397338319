import React from "react";
import "./Download.css";
import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import { getGenus } from "../../actions/genus";
import { download } from "../../actions/download";
import { optionsReturn } from "../../utilities/searchChar";
import { Row, Col, Container } from "react-bootstrap";

// sub-components
import DownloadFilters from "./DownloadFilters";
import { toast } from "react-toastify";

const { Parser } = require("json2csv");

class DownloadPage extends React.Component {
  constructor(props) {
    super();
    this.totalCharacteristics = [
      "Family Name",
      "Displayed Common Name",
      "Alternative Common Name(s)",
      "Displayed?",
      "Generic Common Name(s)",
      "Ethnobotanical Info / Tree Use",
      "Memo",
      "Pacific Islands",
      "Photo Locations",
      "Landscape Application",
      "Landscape Use",
      "Native Range",
      "California Native",
      "Invasive to California",
      "Invasive to Hawaii",
      "Invasive Text",
      "Height (min)",
      "Height (max)",
      "Width (min)",
      "Width (max)",
      "Growth (min)",
      "Growth (max)",
      "Foliage Type",
      "Tree Shape",
      "Litter Type",
      "Bark Color",
      "Bark Texture",
      "Branch Strength (min)",
      "Branch Strength (max)",
      "Leaf Form",
      "Leaf Arrangement",
      "Leaf Shape",
      "Leaf Color",
      "Fall Color",
      "Fruit Size",
      "Fruit Color",
      "Fruit Type",
      "Fruit Value",
      "Fruiting Time",
      "Flower Showiness",
      "Fragrance",
      "Flower Color",
      "Flower Time",
      "USDA Hardiness Zones",
      "Sunset Climate Zones",
      "Recommended Planting Area",
      "Soil Textures",
      "Salt Tolerance",
      "Shade Tolerant",
      "Soil PH (min)",
      "Soil PH (max)",
      "Pest Resistant",
      "Pest Susceptible",
      "Disease Resistant",
      "Disease Susceptible",
      "Root Damage Potential",
      "Health Hazards",
      "Attracts Wildlife",
      "Biogenic Emissions",
      "Deer Palatable",
      "SelecTree Water Use Rating",
      "Flower Type",
      "Schoolyard",
      "Schoolyard use notes",
      "Appraisal",
      "Suggested LCANT (WCISA)",
      "Group Rating",
      "Climate Adapted",
    ];
    this.state = {
      wantedChars: this.totalCharacteristics,
      genus: 0,
      family: 0,
      minPhotos: 0,
      maxPhotos: 60,
      missingInfo: [],
      toggleFlag: "abc",
    };
  }

  getGenusFields = async () => {
    let g = await getGenus();
    let genusFields = g.data[0];
    return genusFields;
  };

  crossLine = event => {
    const element = event.target;
    element.classList.toggle("crossed-line");
    let currentChar = element.innerText;
    if (!this.state.wantedChars.includes(currentChar)) {
      this.state.wantedChars.push(currentChar);
    } else {
      this.state.wantedChars.splice(
        this.state.wantedChars.indexOf(currentChar),
        1
      );
    }

    console.log("this.state.wantedChars", this.state.wantedChars);
  };

  crossColumn = event => {
    //console.log("Clicked! event:", event);
    const element = event.target;
    let currentColumn = element.innerText;

    //console.log("currentColumn:\n", currentColumn);
    var columnsChars = document.getElementsByClassName(currentColumn);

    for (let i = 0; i < columnsChars.length; i++) {
      var currentChar = columnsChars[i];
      if (i == 0) {
        if (currentChar.className == currentColumn + " marked") {
          currentChar.className = currentColumn;
        } else {
          currentChar.className = currentColumn + " marked";
        }
      } else if (i != 0) {
        let currentText = currentChar.innerText;
        if (columnsChars[0].className == currentColumn + " marked") {
          currentChar.classList.add("crossed-line");
          if (this.state.wantedChars.includes(currentText)) {
            this.state.wantedChars.splice(
              this.state.wantedChars.indexOf(currentText),
              1
            );
          }
        } else {
          currentChar.classList.remove("crossed-line");
          this.state.wantedChars.push(currentText);
        }
      }
    }
    console.log("this.state.wantedChars", this.state.wantedChars);
  };

  mapToColumns(wantedChars) {
    var chars = [];
    for (let i = 0; i < wantedChars.length; i++) {
      var currentChar = wantedChars[i];
      switch (currentChar) {
        //NAME CHARACTERISTICS
        case "Family Name":
          chars.push("family");
          break;
        case "Displayed Common Name":
          chars.push("displayed_common_name");
          break;
        case "Alternative Common Name(s)":
          chars.push("alternative_common_names");
          break;
        case "Generic Common Name(s)":
          chars.push("generic_common_names");
          break;
        case "Synonyms":
          chars.push("synonyms");
          break;

        //GENERAL INFO
        case "Displayed?":
          chars.push("display");
          break;
        case "Pacific Islands":
          chars.push("pacific_island");
          break;
        case "Memo":
          chars.push("memo");
          break;
        case "Ethnobotanical Info / Tree Use":
          chars.push("ethnobotanical_info");
          break;
        case "Photo Locations":
          chars.push("photolocations");
          break;
        case "Landscape Application":
          chars.push("landscape_application");
          break;
        case "Landscape Use":
          chars.push("landscape_use");
          break;
        case "Native Range":
          chars.push("native_range");
          break;
        case "California Native":
          chars.push("california_native");
          break;
        case "Invasive to California":
          chars.push("ca_invasive");
          break;
        case "Invasive to Hawaii":
          chars.push("hi_native");
          break;
        case "Invasive Text":
          chars.push("invasive_text");
          break;

        //TREE CHARACTERISTICS
        case "Height (min)":
          chars.push("height_low");
          break;
        case "Height (max)":
          chars.push("height_high");
          break;
        case "Width (min)":
          chars.push("width_low");
          break;
        case "Width (max)":
          chars.push("width_high");
          break;
        case "Growth (min)":
          chars.push("growth_rate_low");
          break;
        case "Growth (max)":
          chars.push("growth_rate_high");
          break;
        case "Foliage Type":
          chars.push("foliage_type");
          break;
        case "Tree Shape":
          chars.push("tree_shape");
          break;
        case "Litter Type":
          chars.push("litter_type");
          break;
        case "Bark Color":
          chars.push("bark_color");
          break;
        case "Bark Texture":
          chars.push("bark_texture");
          break;
        case "Branch Strength (min)":
          chars.push("branch_strength_low");
          break;
        case "Branch Strength (max)":
          chars.push("branch_strength_high");
          break;
        case "Leaf Form":
          chars.push("leaf_form");
          break;
        case "Leaf Arrangement":
          chars.push("leaf_arrangement");
          break;
        case "Leaf Shape":
          chars.push("leaflet_shape");
          break;
        case "Leaf Color":
          chars.push("foliage_growth_color");
          break;
        case "Fall Color":
          chars.push("foliage_fall_color");
          break;
        case "Fruit Size":
          chars.push("fruit_size");
          break;
        case "Fruit Color":
          chars.push("fruit_color");
          break;
        case "Fruit Type":
          chars.push("fruit_type");
          break;
        case "Fruit Value":
          chars.push("fruit_value");
          break;
        case "Fruiting Time":
          chars.push("fruiting_time");
          break;
        case "Flower Showiness":
          chars.push("flower_showiness");
          break;
        case "Fragrance":
          chars.push("fragrance");
          break;
        case "Flower Color":
          chars.push("flower_color");
          break;
        case "Flower Time":
          chars.push("flower_time");
          break;
        case "Flower Type":
          chars.push("flower_type");
          break;

        //SITE CONDITIONS
        case "USDA Hardiness Zones":
          chars.push("usda_zone");
          break;
        case "Sunset Climate Zones":
          chars.push("sunset_zone");
          break;
        case "Recommended Planting Area":
          chars.push("planting_area");
          break;
        case "Soil Textures":
          chars.push("soil_texture");
          break;
        case "Salt Tolerance":
          chars.push("salt_tolerance");
          break;
        case "Shade Tolerant":
          chars.push("shade_tolerant");
          break;
        case "Soil PH (min)":
          chars.push("soil_ph_low");
          break;
        case "Soil PH (max)":
          chars.push("soil_ph_high");
          break;
        case "SelecTree Water Use Rating":
          chars.push("water_use");
          break;
        case "Schoolyard":
          chars.push("schoolyard");
          break;
        case "Schoolyard Use":
          chars.push("schoolyard_use_notes");
          break;

        //CONSIDERATIONS
        case "Pest Resistant":
          chars.push("pest_resistant");
          break;
        case "Pest Susceptible":
          chars.push("pest_susceptibility");
          break;
        case "Disease Resistant":
          chars.push("disease_resistant");
          break;
        case "Disease Susceptible":
          chars.push("disease_susceptibility");
          break;
        case "Root Damage Potential":
          chars.push("root_damage_potential");
          break;
        case "Health Hazards":
          chars.push("health_hazard");
          break;
        case "Attracts Wildlife":
          chars.push("attracts_wildlife");
          break;
        case "Biogenic Emissions":
          chars.push("biogenic_emissions");
          break;
        case "Deer Palatable":
          chars.push("deer_palatable");
          break;
        case "Appraisal":
          chars.push("appraisal");
          break;
        case "Suggested LCANT (WCISA)":
          chars.push("app_lcant");
          break;
        case "Group Rating":
          chars.push("app_grrating");
          break;

        case "Climate Adapted":
          chars.push("inland_empire_adapted");
          chars.push("inland_valleys_adapted");
          chars.push("interior_west_adapted");
          chars.push("northern_california_coast_adapted");
          chars.push("southern_california_coast_adapted");
          chars.push("southern_desert_adapted");
          break;
      }
    }
    return chars;
  }

  optionsMissingInfo = () => {
    try {
      return optionsReturn(this.state);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.DownloadFilters.optionsFamily: ${err}`
      );
    }
  };

  optionsGenus = async () => {
    try {
      return optionsReturn(this.state);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.DownloadFilters.optionsGenus: ${err}`
      );
    }
  };

  optionsFamily = () => {
    try {
      return optionsReturn(this.state);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.DownloadFilters.optionsFamily: ${err}`
      );
    }
  };

  downloadFile = async () => {
    try {
      let chars = this.mapToColumns(this.state.wantedChars);
      const currentMissingInfo = this.mapToColumns(this.state.missingInfo).map(
        attrib => `missing=${attrib}`
      );

      let parser = new Parser();

      chars = [...chars, ...currentMissingInfo];

      if (this.state.genus)
        chars.push(`genus_filter=${this.state.genus.trim()}`);
      if (this.state.family)
        chars.push(`family_filter=${this.state.family.trim()}`);

      if (this.state.minPhotos !== 0 || this.state.maxPhotos !== 60) {
        if (this.state.minPhotos !== undefined)
          chars.push(`photo_filter_min=${this.state.minPhotos}`);
        if (this.state.maxPhotos !== undefined)
          chars.push(`photo_filter_max=${this.state.maxPhotos}`);
      }

      let response = await toast.promise(download(chars), {
        pending: "Attempting Download",
        success: "Download Success!",
        error: "Download Failed!",
      });
      var infoToggle = document.getElementsByClassName("toggle1")[0].innerText;

      try {
        let csvdata = parser.parse(response.data);
        let fileName = "treedata.csv";
        let fileType = "text/csv";

        let blob = new Blob([csvdata], { type: fileType });

        let a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
      } catch {
        alert("There is no trees with these filters!");
      }
    } catch (err) {
      console.log(`components.DownloadPage.downloadFile: ${err}`);
    }
  };

  render() {
    return (
      <div class="download">
        <h1>Download Tree Data</h1>
        <hr></hr>

        <Container className="column-filters">
          <Row>
            <Col>
              <Row>
                <Col>
                  <h4
                    className="Name Characteristics"
                    id="1"
                    onClick={this.crossColumn}
                  >
                    Name Characteristics
                  </h4>

                  <button
                    className="Name Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Family Name{" "}
                  </button>
                  <button
                    className="Name Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Displayed Common Name{" "}
                  </button>
                  <button
                    className="Name Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Alternative Common Name(s){" "}
                  </button>
                  <button
                    className="Name Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Generic Common Name(s){" "}
                  </button>
                  <button
                    className="Name Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Synonyms{" "}
                  </button>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h4
                    className="General Information"
                    id="2"
                    onClick={this.crossColumn}
                  >
                    General Information
                  </h4>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Displayed?{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Pacific Islands{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Memo{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Ethnobotanical Info / Tree Use{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Photo Locations{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Landscape Application{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Landscape Use{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Native Range{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    California Native{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Invasive to California{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Invasive to Hawaii{" "}
                  </button>
                  <button
                    className="General Information"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Invasive Text{" "}
                  </button>
                </Col>
              </Row>
            </Col>
            <Col>
              <h4 className="Tree Characteristics" onClick={this.crossColumn}>
                Tree Characteristics
              </h4>
              <Row>
                <Col>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Height (min){" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Height (max){" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Width (min){" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Width (max){" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Growth (min){" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Growth (max){" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Foliage Type{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Tree Shape{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Litter Type{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Bark Color{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Bark Texture{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Branch Strength (min){" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Branch Strength (max){" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Fragrance{" "}
                  </button>
                </Col>
                <Col>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Leaf Form{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Leaf Arrangement{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Leaf Shape{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Leaf Color{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Fall Color{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Fruit Size{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Fruit Color{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Fruit Type{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Fruit Value{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Fruiting Time{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Flower Showiness{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Flower Color{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Flower Time{" "}
                  </button>
                  <button
                    className="Tree Characteristics"
                    onClick={this.crossLine}
                  >
                    {" "}
                    Flower Type{" "}
                  </button>
                </Col>
              </Row>
            </Col>
            <Col>
              <h4 className="Site Conditions" onClick={this.crossColumn}>
                Site Conditions
              </h4>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                USDA Hardiness Zones{" "}
              </button>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                Sunset Climate Zones{" "}
              </button>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                Recommended Planting Area
              </button>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                Soil Textures{" "}
              </button>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                Salt Tolerance{" "}
              </button>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                Shade Tolerant{" "}
              </button>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                Soil PH (min){" "}
              </button>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                Soil PH (max)
              </button>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                SelecTree Water Use Rating
              </button>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                Schoolyard
              </button>

              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                Schoolyard Use
              </button>
              <button className="Site Conditions" onClick={this.crossLine}>
                {" "}
                Climate Adapted
              </button>
            </Col>
            <Col>
              <h4 className="Considerations" onClick={this.crossColumn}>
                Considerations
              </h4>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Pest Resistant{" "}
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Pest Susceptible{" "}
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Disease Resistant{" "}
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Disease Susceptible{" "}
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Root Damage Potential{" "}
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Health Hazards
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Attracts Wildlife{" "}
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Biogenic Emissions{" "}
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Deer Palatable{" "}
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Appraisal{" "}
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Suggested LCANT (WCISA){" "}
              </button>
              <button className="Considerations" onClick={this.crossLine}>
                {" "}
                Group Rating{" "}
              </button>
            </Col>
          </Row>
        </Container>

        <Container className="download-filters">
          <ResponsiveAccordion title="Filter By">
            <DownloadFilters parentComponent={this} parentState={this.genus} />
          </ResponsiveAccordion>
          <div className="toggle1">
            <Toggle className="t1"></Toggle>
          </div>
        </Container>

        <p>
          <br></br>Download a CSV file containing data on trees currently
          uploaded to SelecTree!
        </p>
        <button
          type="submit"
          class="btn btn-primary btn-lg"
          onClick={this.downloadFile}
        >
          Download File
        </button>

        <p>
          <i></i>
        </p>
      </div>
    );
  }
}

class Toggle extends React.Component {
  state = {
    textflag: false,
  };

  ToggleButton() {
    this.setState({ textflag: !this.state.textflag });
  }

  render() {
    return (
      <div>
        <button
          class="btn btn-primary btn-sm"
          onClick={() => this.ToggleButton()}
        >
          {this.state.textflag === false ? "And" : "Or"}
        </button>
        {!this.state.textflag && this.props.text}
      </div>
    );
  }
}

export default DownloadPage;

import { translateFilterOptions, getFilterOptions } from "./sessionStorage";

export const optionsReturn = options => {
  try {
    return options
      .filter(item => item)
      .map((option, idx) => {
        return { label: option, value: ++idx };
      });
  } catch (err) {
    console.log(`utilities.searchChar.OptionsReturn: ${err}`);
  }
};

export const optionsReturnForForm = options => {
  try {
    return options.reduce((filtered, option) => {
      if (option) {
        filtered.push({ label: option, value: option });
      }
      return filtered;
    }, []);
  } catch (err) {
    console.log(`utilities.searchChar.OptionsReturn: ${err}`);
  }
};

export const filterDataObj = filterData => {
  try {
    if (!filterData) {
      return;
    }
    // Dictionary for array val for conversion
    const filterDict = {
      flower_color: 1,
      fruit_type: 1,
      fragrance: 1,
      ca_native: 1,
      hi_native: 1,
      fall_color: 1,
      usda_zones: 1,
      sunset_climate_zone: 1,
      deer_tolerance: 1,
      shade_tolerant: 1,
      schoolyard: 1,
      salt_tolerance: 1,
      water_use_rating: 1,
      sun_exposure: 1,
      utility_precautions: 1,
      powerline_friendly: 1,
      climate_region: 1,
      zip_code: 1,
      adapted_zip_code: 1,
      climate_adapted: 1,
    };
    const dataAsArray = filterData.split("?").map(data => {
      let dataTranslation = data.split("=");
      if (filterDict[dataTranslation[0]]) {
        dataTranslation[1] = dataTranslation[1].split(",");
      }

      return dataTranslation;
    });

    let filterObj = Object.fromEntries(dataAsArray);
    delete filterObj.genus;
    delete filterObj.tree_suggestions;

    return filterObj;
  } catch (err) {
    console.log(`utilites.searchChar.filterDataObj: ${err}`);
  }
};

export const filterParams = params => {
  try {
    let filterData = getFilterOptions();
    const filterDataLength = params.filterData.split("?").length;

    if (filterDataLength > 1) {
      filterData = translateFilterOptions(filterData);
    } else {
      filterData = params.filterData;
    }

    return filterData;
  } catch (err) {
    console.log(`utilities.searchChar.filterParams: ${err}`);
  }
};
